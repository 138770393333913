angular.module("app.config", [])
.constant("envConfig", {"envName":"staging","APIURL":"https://fhtei84br6.execute-api.us-east-1.amazonaws.com/v1","esURL":"https://19uyc1z7ve.execute-api.us-east-1.amazonaws.com/v1","hubspot":{"utkAPIURL":"https://orders.synthego.com/profile/hubspot-profile/utk"},"store":{"APIURL":"https://api.synthego.com","APIKey":"WANYqXrAIukAqV6JP43PtgOqzTDtd5r3kphQl0PWImZR0g6Bqhf9RMWfKVRveEg1"},"gko":{"id":"homo_sapiens_gencode_26_v3","genomeSourceShortname":"homo_sapiens_gencode_26_primary"}})
.constant("version", "1.3")
.constant("appPath", "./app")
.constant("assetsPath", "./app/assets")
.constant("commonPath", "./app/common")
.constant("buildPath", "./dist")
.constant("depPaths", ["./app/bower_components/angular/angular.min.js","./app/bower_components/angular-ui-router/release/angular-ui-router.min.js","./app/bower_components/angular-sanitize/angular-sanitize.min.js","./app/bower_components/angular-animate/angular-animate.min.js","./app/bower_components/angular-messages/angular-messages.min.js","./app/bower_components/angular-cookies/angular-cookies.min.js","./app/bower_components/angular-flash-alert/dist/angular-flash.min.js","./app/bower_components/angular-ui-select/dist/select.min.js","./app/bower_components/angular-ui-validate/dist/validate.min.js","./app/bower_components/angular-bootstrap/ui-bootstrap-tpls.min.js","./app/bower_components/elasticsearch/elasticsearch.angular.min.js","./app/bower_components/ngstorage/ngStorage.min.js","./app/bower_components/d3/d3.min.js","./app/bower_components/particles.js/particles.min.js","./app/bower_components/file-saver/FileSaver.min.js","./app/bower_components/waypoints/lib/noframework.waypoints.min.js","./app/bower_components/angular-mocks/angular-mocks.js","./app/bower_components/angulartics/dist/angulartics.min.js","./app/bower_components/angulartics-google-analytics/dist/angulartics-ga.min.js","./app/bower_components/angulartics-hubspot/dist/angulartics-hubspot.min.js","./app/bower_components/jquery/dist/jquery.slim.min.js","./app/bower_components/angular-tablesort/js/angular-tablesort.js"])
.constant("rootUrls", {"labs":"tools-stage.synthego.com","ice":"icestage.synthego.com","design":"design-stage.synthego.com"});

'use strict';

angular
  .module('app', [
    'ui.router',
    'ui.bootstrap',
    'ngAnimate',
    'ngSanitize',
    'ngCookies',
    'ngMessages',
    'ngStorage',
    'ngFlash',
    'ui.select',
    'ui.validate',
    'elasticsearch',
    'angulartics',
    'angulartics.google.analytics',
    'angulartics.hubspot',
    'tableSort',
    'app.config'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    '$urlMatcherFactoryProvider',
    'FlashProvider',
    function(
      $stateProvider,
      $urlRouterProvider,
      $locationProvider,
      $urlMatcherFactoryProvider,
      FlashProvider
    ) {
      // An array of state definitions
      var states = [
        {
          name: 'error',
          templateUrl: './views/error.2d9b67e4.html',
          controller: 'error',
          params: {
            message: 'Something went wrong.',
            backTo: 'design'
          },
          data: {
            title: 'Error',
            pageClass: 'error'
          }
        },
        {
          name: 'design',
          url: '/',
          templateUrl: './views/design/design.b291da96.html',
          controller: 'designForm',
          data: {
            title: 'Knockout Guide Designer',
            pageClass: 'design'
          }
        },
        {
          name: 'design.results',
          url: 'design/results?genome&nuclease&gene_id&symbol&direct&exon',
          abstract: true,
          reloadOnSearch: false,
          views: {
            '@': {
              templateUrl: './views/design/design-results.fd8208d0.html',
              controller: 'designResults'
            }
          },
          data: {
            title: 'Guide Design Results',
            pageClass: 'design-results results-view'
          }
        },
        {
          name: 'design.results.recommended',
          url: '',
          views: {
            'results@design.results': {
              templateUrl: './views/design/_design-recommended.a73ee01d.html'
            }
          },
          data: {
            title: 'Guide Design Results',
            pageClass: 'design-results results-view'
          }
        },
        {
          name: 'design.results.advanced',
          url: '/advanced',
          views: {
            'results@design.results': {
              templateUrl: './views/design/_design-advanced.2d47bf96.html'
            }
          },
          data: {
            title: 'Guide Design Results',
            pageClass: 'design-results results-view'
          }
        },
        {
          name: 'design.results.summary',
          url: '/summary',
          params: {
            guides: null,
            dx: null
          },
          views: {
            '@': {
              templateUrl: './views/shared/results-summary.cfb8fd8e.html',
              controller: 'resultsSummary'
            }
          },
          data: {
            title: 'Guide Design - CRISPR Experimental Workflow',
            pageClass: 'results-summary results-view'
          }
        },
        {
          name: 'validate',
          url: '/validate',
          templateUrl: './views/validate/validate.c2ce8a2f.html',
          controller: 'validateForm',
          data: {
            title: 'Knockout Guide Validator',
            pageClass: 'validate'
          }
        },
        {
          name: 'validate.results',
          url: '/results?genome&nuclease&guide',
          views: {
            '@': {
              templateUrl: './views/validate/validate-results.21a552ca.html',
              controller: 'validateResults'
            }
          },
          data: {
            title: 'Guide Validation Results',
            pageClass: 'validate-results results-view'
          }
        }
      ];

      // Loop over the state definitions and register them
      states.forEach(function(state) {
        $stateProvider.state(state);
      });

      $urlRouterProvider.otherwise('/');
      $locationProvider.hashPrefix('');

      // Match trailing slashes in URLs
      $urlMatcherFactoryProvider.strictMode(false);

      // Enable this once index document is set on AWS
      //$locationProvider.html5Mode(true);

      FlashProvider.setTimeout(0);
    }
  ])

  .run([
    '$rootScope',
    '$state',
    '$stateParams',
    '$transitions',
    '$uibModalStack',
    'Flash',
    'envConfig',
    'version',
    'rootUrls',
    'hubspot',
    function(
      $rootScope,
      $state,
      $stateParams,
      $transitions,
      $uibModalStack,
      Flash,
      envConfig,
      version,
      rootUrls,
      hubspot
    ) {
      // Make states available for classes and conditionals
      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;

      // Navbar initial state
      $rootScope.isNavCollapsed = true;
      $rootScope.isCollapsed = false;

      $rootScope.number_of_sites = '113,934,776,281';
      $rootScope.number_of_genomes = '123,769';
      $rootScope.number_of_coding_genes = '19,817';

      $rootScope.helpText = {
        guide_type_short:
          'Modified sgRNAs provide increased editing efficiency for challenging cell types such as primary or stem cells, plants or prokaryotes. Both modified and unmodified sgRNA guides offer better in vivo stability, better scalability and easier workflow compared to crRNA:tracrRNA. Target sequences are identical between modified and unmodified sgRNAs.',
        guide_type:
          'Modified sgRNAs provide increased editing efficiency for challenging cell types such as primary or stem cells, plants or prokaryotes. Both modified and unmodified sgRNA guides offer better in vivo stability, better scalability and easier workflow compared to crRNA:tracrRNA. Target sequences are identical between modified and unmodified sgRNAs.',
        recommended_rank:
          'Rank based upon four criteria, sorted by cut position.',
        rank: 'Sorted by cut position.',
        sequence: 'Guide sequence of the sgRNA molecule.',
        cutsite: 'Location of predicted double-stranded break.',
        exon: 'The exon where this target is located (if available).',
        on_target_score: 'Score based upon Doench et. al, 2016.',
        off_targets:
          ' Number of potential off targets based on number of mismatches.',
        early_coding:
          'The target is located towards the start of the coding sequence of the gene.',
        common_exon:
          'The target is located in an exon that occurs in the majority of transcripts.',
        high_activity:
          'The guide has a high activity score (Doench, et. al, 2016).',
        minimal_off_targets: 'The guide has no 0 or 1 base mismatches.',
        recommended_guides:
          'This tool designs CRISPR guide RNA specifically for gene Knockouts.',
        off_target_site:
          'Differences between guide RNA and offtarget site are highlighted below.',
        mismatches:
          'The total number of mismatches between guide RNA and offtarget site.',
        chromosome: 'The chromosome or contig where this target is located.',
        pam: 'The NGG sequence ',
        gene: 'Other potential genes targeted by this guide RNA.'
      };

      $rootScope.envConfig = envConfig;
      $rootScope.version = version;
      $rootScope.date = new Date();
      $rootScope.rootUrls = rootUrls;

      // Triggers on any transition
      $transitions.onStart({ to: '**' }, function(transition) {
        // Clear modals whenever state starts to change and cancel transition
        var top = $uibModalStack.getTop();
        if (top) {
          $uibModalStack.dismiss(top.key);
          $state.reload();
        }
        // Clear any flash messages
        Flash.clear();
      });

      // Keeps the promo from flashing for current customers
      $rootScope.hsContact = {
        is_customer: true
      };

      hubspot
        .getContactData()
        .then(function(response) {
          console.log('Hubspot API success', response);
          $rootScope.hsContact = response;
        })
        .catch(function(error) {
          console.log('Hubspot API error', error);
          // On error assume they are not a customer
          $rootScope.hsContact = {
            is_customer: false
          };
        });
    }
  ]);

angular.module('app')
  .directive('designLoader', function(){
    return {
      restrict: 'EA',
      scope: {
        dx: '=',
        status: '=',
        searchDelay: '@'
      },
      templateUrl: './views/design/_design-loader.b7bff49a.html',
      controller: ['$scope', '$stateParams', '$window', '$timeout', '$http', 'userSelections', 'es', function($scope, $stateParams, $window, $timeout, $http, userSelections, es) {

        //If we're actually not hitting the API, assume we're loading from cache and skip loader
        if($http.pendingRequests.length == 0) {
          $scope.status.loading = false;
        }

        $scope.selectedTarget = userSelections.target;

        // If we don't have target information already
        if($scope.selectedTarget.genome == null) {
          var query = {
              'query':
              {
                'match': {
                  'shortname': $stateParams.genome
                }
              }
            };

          // Get the genome info for display
          es.search({
            index: 'genome_indices',
            size: 1,
            method: 'GET',
            source: JSON.stringify(query)
             
          }).then(function(response) {

            // Set the selected genome
            $scope.selectedTarget.genome = { value: response.hits.hits[0] };

            // If we don't have target information already and there is a symbol param available
            if($scope.selectedTarget.gene == null && $stateParams.symbol != undefined) {

              var query = {
                'query': {
                  'bool': {
                    'must': {
                      'match': {
                        'symbol': $stateParams.symbol
                      }
                    },
                    'filter': [
                      {'term': { 'tax_id': $scope.selectedTarget.genome.value._source.tax_id }}
                    ]
                  }
                }
              };

              es.search({
                index: 'gene_indices',
                size: 1,
                method: 'GET',
                source: JSON.stringify(query)
                 
              }).then(function(response) {

                // Set the selected gene
                $scope.selectedTarget.gene = { value: response.hits.hits[0] };
                
              });
            }

          });

        }


        // Logic for showing loading screen
        $scope.$watch('status.loading', function (loading) {

          if(loading == true) {

            // Load particles
            particlesJS.load('particles-js', 'misc/particles.json');

            // TODO FIX
            var delay = $scope.searchDelay;
            var itemCount = 7;

            $scope.loadingCountdown = itemCount;
            $scope.timerFinished = false;

            // How random the difference in loading should be
            var delayMultiplier = function() {
              return Math.random() * 2;
            }

            // For randomness, instead of setinterval we call a timeout from within itself with the above multiplier
            var countdownRandom = function() {
              $scope.loadingCountdown --
              $scope.countdown = $timeout(countdownRandom, (delay / itemCount) * delayMultiplier());
            };

            $scope.countdown = $timeout(countdownRandom, (delay / itemCount) * delayMultiplier());

          } else {
            // If we're done loading kill particles
            if($window.pJSDom[0]) {
              $window.pJSDom[0].pJS.fn.vendors.destroypJS();
              $window["pJSDom"] = [];
            }

          }
        });

        // After the countdown is up, if the results are there, we're done loading
        $scope.$watch('loadingCountdown', function (count) {
          if(count < 1) {
            $scope.timerFinished = true;

            if($scope.dx) {
              if($scope.dx.guides != undefined) {
                $scope.status.loading = false;
                $timeout.cancel($scope.countdown);
              }
            }
          }
        });

        // If the results are there and the timer is finished, we're done loading
        $scope.$watch('dx.guides', function (guides) {

          if($scope.timerFinished && guides != undefined) {
            $scope.status.loading = false;
            $timeout.cancel($scope.countdown);
          }
          
        });

      }]
    }
  });
angular.module('app')
  .directive('designTable', function(){
    return {
      restrict: 'EA',
      scope: {
        dx: '=',
        guideLimit: '=',
        guideCollection: '=',
        tableType: '@',
        tableOrder: '@',
        tableFilter: '=',
        tableLimit: '=',
        helpText: '=',
        activeGuide: '=',
        vizHeight: '='
      },
      templateUrl: './views/design/_design-table.1c7e01bb.html'
    }
  });

angular.module('app')
  .directive('designViz', ['$window', '$filter', function($window, $filter) {
    return {
      restrict: 'EA',
      scope: {
        dx: '=',
        guideFilter: '=',
        guideLimit: '=',
        activeGuide: '=',
        vizHeight: '=?'
      },
      template: '<div class="viz-wrapper"></div>',
      replace: true,
      link: function(scope, element, attrs) {

        var d3 = $window.d3;

        var margin = {
          top: 50,
          right: 50,
          bottom: 50,
          left: 50
        };

        // Base SVG element
        var svg = d3.select(element[0])
          .append('svg')
          .attr('class', 'viz-design')
          .attr('width', '100%')
          .append('g')
          .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

        // Tooltip element
        var tooltip = d3.select('.viz-wrapper')
          .append('div')
          .attr('class', 'viz-tooltip');

        tooltip.append('div').attr('class', 'info rank');
        tooltip.append('div').attr('class', 'info target');
        tooltip.append('div').attr('class', 'info location');
        tooltip.append('div').attr('class', 'info exon');
        tooltip.append('div').attr('class', 'info on-target-score');
        tooltip.append('div').attr('class', 'info off-targets');


        // Browser onresize event
        $window.onresize = function() {
          scope.$digest();
        };

        // Watch for brower resize event and re-render
        scope.$watchCollection(function() {
          return [angular.element($window)[0].innerWidth, angular.element($window)[0].innerHeight];
        }, function(newValue, oldValue) {
          if(!angular.equals(newValue, oldValue)) {
            console.log('Window resized');
            scope.render(scope.dx, scope.dx.guides);
          }
        });

        // If new data comes in re-render
        scope.$watch('dx.guides', function(){
          scope.render(scope.dx, scope.dx.guides);
        });

        scope.$watch('guideLimit', function(newLimit, oldLimit) {
          if(!angular.equals(newLimit, oldLimit)) {
            console.log('Guide limit changed');
            scope.render(scope.dx, scope.dx.guides);
          }
        });

        // Watch active guide and set active class
        scope.$watch('activeGuide.id', function(newID, oldID) {
          svg.select('#guide-' + oldID).classed('active', false)
          svg.select('#guide-' + newID).classed('active', true);
        });

        scope.render = function(data, guides) {
          console.log('Rendering visualization');

          var posGuides;
          var negGuides;
          var posYCount
          var negYCount
          var height;
          var outerHeight;
          var width;
          var x;
          var y;
          var xAxis;
          var yAxis;
          var format = d3.format(','); // For formatting numbers with commas

          // Remove all previous items before render
          svg.selectAll('*').remove();

          // 
          // Filter and sort the guides
          //

          // Put picked guides first if there are more guides than the limit
          // Since the guideLimit is 'Infinity' for showing all guides they will also not be ordered when showing all
          if (scope.dx.guides.length > scope.guideLimit) {
            // If we're showing a load more button order guides by pick_order
            guides = $filter('orderBy')(guides, 'pick_order');
          }

          // Then apply any filter
          guides = $filter('filter')(guides, scope.guideFilter);

          // Then apply any limit
          guides = $filter('limitTo')(guides, scope.guideLimit);

          // Sort them by pam_right and cutsite
          guides = $filter('orderBy')(guides, ['pam_right','cutsite']);

          // Get the starting and ending positions of the lines based on the cutsite
          // Optional markerOffset for calculating spacing to include space for arrowhead
          var guidePosition = function(guide, markerOffset) {

            markerOffset = markerOffset || 0;

            if(guide.pam_right) {
              return {
                x1: guide.cutsite - 16,
                x2: guide.cutsite + 3 + markerOffset
              }
            } else {
              return {
                x1: guide.cutsite + 16,
                x2: guide.cutsite - 3 - markerOffset
              }
            }
          }

          // Find the y position for all the guides
          // There must be a more elegant way to achieve this

          var getYPos = function(guides) {
            //Since we don't know y.rangeBand() yet, calculate a logical spacing to include the marker in the overlap calculations
            var markerOffset = (data.viz.end - data.viz.start)/175;

            for(var i = 0; i < guides.length; i++) {
              var guide = guides[i];

              // Get the current guide's xPos
              var ax1 = guidePosition(guide).x1;
              var ax2 = guidePosition(guide, markerOffset).x2;

              if(!guide.yPos) {

                // Try to put it in position
                // Loop through all available y positions until we find an empty one
                for (var yPos = 2; yPos < guides.length + 2; yPos++) { 

                  var overlaps = false;

                  // Get all guides at that position
                  var guidesAtY = $filter('filter')(guides, {
                    yPos: guide.pam_right ? yPos : -yPos
                  });

                  // If there are no guides, there will be no overlaps
                  if(guidesAtY.length == 0) {
                    overlaps = false;
                  }

                  // Loop through all guides at the position we're looking at to find overlaps
                  for(var i = 0; i < guidesAtY.length; i++) {

                    var yGuide = guidesAtY[i];

                    // Get their position
                    var bx1 = guidePosition(yGuide).x1;
                    var bx2 = guidePosition(yGuide, markerOffset).x2;  

                    // Do they overlap with the guide we're trying to place?
                    if ((guide.pam_right && ax1 <= bx2 && bx1 <= ax2) || (!guide.pam_right && ax1 >= bx2 && bx1 >= ax2)) {
                      // There's an overlap at this position, give up
                      overlaps = true;
                      break;
                    } 

                  };

                  // We looped through them all and found no overlaps
                  if(!overlaps) {
                    // Set the guide y position
                    guide.yPos = guide.pam_right ? yPos : -yPos;
                    break;
                  }

                }
              }
            }
          }

          getYPos(guides);

          // Get the number of y values above and below the y axis by grabbing the largest and smallest yPos
          // Add 2 to each for some padding

          posYCount = Math.max.apply(Math,guides.map(function(o){return o.yPos > 0 ? o.yPos : 0;})) + 2;
          negYCount = Math.min.apply(Math,guides.map(function(o){return o.yPos < 0 ? o.yPos : 0;})) - 2;

          // 
          // Set the height and width of the visualization
          //

          // If there are fewer guides, the height can be less
          // Otherwise it should never be more than 1/3 of viewport
          var calculatedHeight = ((posYCount + -negYCount) * 15) + 50;
          var thirdViewport = $window.innerHeight / 3 - margin.top - margin.bottom;

          if (calculatedHeight > thirdViewport) {
            height = Math.floor(thirdViewport);
          } else {
            height = Math.floor(calculatedHeight);
          }

          outerHeight = height + margin.top + margin.bottom;

          // Expose the height to scope to be used by sticky table headers
          scope.vizHeight = {
            value: outerHeight
          };

          // Set the actual height
          d3.select('.viz-design').attr('height', outerHeight);

          width = d3.select(element[0]).node().offsetWidth - margin.left - margin.right;


          // 
          // Calculate the x and y scales of the visualization
          //            

          // Set the xscale
          x = d3.scale.linear().range([0, width]);
          x.domain([data.viz.start, data.viz.end]);

          // Set the yscale
          y = d3.scale.ordinal().rangeRoundBands([0, height], .2);
          // Set the y domain based on the number of positive and negative guides
          y.domain(d3.range(negYCount, posYCount).reverse());

          // Add a background rectangle for mouse events
          svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', '#fff')

          // 
          // Draw the axes
          //  

          // Define and draw the x axis
          xAxis = d3.svg.axis()
            .scale(x)
            .orient('bottom')
            .ticks(5)
            .outerTickSize(0)
            .innerTickSize(-height)
            .tickPadding(10);

          svg.append('g')
            .call(xAxis)
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + height + ')')
            .attr('x', width - 75)
            .attr('dx', '.71em')
            .attr('dy', '-.71em')

          // Define and draw the x axis
          yAxis = d3.svg.axis()
            .scale(y)
            .orient('left')
            .ticks(guides.length + 4);

          // Leave for debugging
          //svg.append('g')
          //  .attr('class', 'y axis')
          //  .call(yAxis);


          // 
          // Add labels
          //  

          // Position the pam labels in each quadrant
          // If there are no guides we don't show the label
          if (posYCount > 0) {
            svg.append('text')
              .attr('y', y(Math.floor(posYCount / 2)))
              .attr('x', 15)
              .attr('text-anchor', 'right')
              .attr('class', 'pam-label')
              .text('\u2795\u00A0 strand');
          }

          if (negYCount < 0) {
            svg.append('text')
              .attr('y', y(Math.floor(negYCount / 2)))
              .attr('x', 15)
              .attr('text-anchor', 'right')
              .attr('class', 'pam-label')
              .text('\u2796\u00A0 strand');
          }

          // 
          // Add SVG definitions
          //  

          svg.append('svg:defs')
            .attr('id', 'svg-defs')

          // The marker end for the strand direction arrow
          svg.select('#svg-defs')
            .append('svg:marker')
            .attr('id', 'strandmarker')
            .attr('refX', 4)
            .attr('refY', 4)
            .attr('markerWidth', 16)
            .attr('markerHeight', 16)
            .attr('orient', 'auto')
            .append('polygon')
            .attr('points', '4 4 0 0 0 8 4 4')

          // The marker end for the guides
          svg.select('#svg-defs')
            .append('svg:marker')
            .attr('id', 'guidemarker')
            .attr('markerUnits', 'userSpaceOnUse')
            .attr('viewBox', '0 0 8 8')
            .attr('refX', 0)
            .attr('refY', 4)
            .attr('orient', 'auto')
            .attr('markerWidth', y.rangeBand())
            .attr('markerHeight', y.rangeBand())
            .append('polygon')
            .attr('points', '4 4 0 0 0 8 4 4')

          // 
          // Draw the guides
          //

          var guideGroup = svg.selectAll('.guide')
            .data(guides)
            .enter()
            .append('g')

          guideGroup.append('line')
            .attr('stroke-width', y.rangeBand())
            .attr('marker-end', 'url(#guidemarker)')
            .attr('class', function(d) {
              var guideClass = 'guide';
              // If the guide is recommended, add the class to make it green
              if (d.pick_order) {
                guideClass += ' picked';
              }
              return guideClass;
            })
            .attr('id', function(d) {
              return 'guide-' + d.id // Add a unique ID for hovering functionality
            }) 
            .each(function(d, i) {
              // Set the yPos from the value we calculated earlier
              d3.select(this).attr({
                y1: y(d.yPos),
                y2: y(d.yPos),
              });
            })
            .each(function(d, i) {
              var x1 = x(guidePosition(d).x1);
              var x2 = x(guidePosition(d).x2);
              d3.select(this)
                .attr('x1', x1)
                .attr('x2', d.pam_right ? x1 + 1 : x1 - 1) // Initially set the line's x2 to x1 so we can animate it, add or subtract so arrow is pointing the right way
                //.attr('opacity', 0)
                .transition()
                  .duration(500)
                  .delay(function(d) {
                    return i * 25; // Stagger animations
                  })
                  .attr('x2', x2) // Animate the line being drawn
                  //.attr('opacity', 1);
            });

            guideGroup.append('line')
              .each(function(d, i) {
                d3.select(this)
                  .attr('class', 'guide-cutsite')
                  .attr('x1', x(d.cutsite))
                  .attr('y1', y(d.yPos) - y.rangeBand()/2)
                  .attr('x2', x(d.cutsite))
                  .attr('y2', y(d.yPos) + y.rangeBand()/2)
                  .attr('opacity', 0)
                  .transition()
                    .duration(500)
                    .delay(function(d) {
                      return i * 25; // Stagger animations
                    })
                    .attr('opacity', 1);
              });

          // 
          // Draw the Y axis line
          //

          svg.append('line')
            .attr('class', 'divider')
            .attr('x1', 0)
            .attr('y1', y(0))
            .attr('x2', width)
            .attr('y2', y(0));

          // 
          // Draw the CDS block and label
          //

          if (data.target_method != undefined && data.target_method <= 1) {

            svg.append('rect')
              .attr('class', 'cds')
              .attr('y', y(0))
              .attr('x', x(data.viz.cds.lowerbound))
              .attr('width', x(data.viz.cds.upperbound) - x(data.viz.cds.lowerbound))
              .attr('height', 0)
              .transition()
              .duration(500)
              .attr('y', y(0) - y.rangeBand())
              .attr('height', y.rangeBand() * 2);

            svg.append('text')
              .attr('y', y(0))
              .attr('x', x(data.viz.cds.lowerbound))
              .attr('text-anchor', 'left')
              .attr('class', 'chart-label cds-label')
              .text('CDS')
              .transition()
              .duration(250)
              .attr('y', y(0) + y.rangeBand() + 9); // A bit extra to account for the height of the text

          }

          // 
          // Draw the Exon block and label
          //

          if (data.target_method != undefined && (data.target_method == 0 || data.target_method == 2)) {

            svg.append('rect')
              .attr('class', 'exon')
              .attr('y', y(0))
              .attr('x', x(data.viz.exon.lowerbound))
              .attr('width', x(data.viz.exon.upperbound) - x(data.viz.exon.lowerbound))
              .attr('height', 0)
              .transition()
              .duration(500)
              .delay(250)
              .attr('y', y(0) - y.rangeBand() / 2)
              .attr('height', y.rangeBand());

            svg.append('text')
              .attr('y', y(0))
              .attr('x', x(data.viz.exon.lowerbound))
              .attr('text-anchor', 'left')
              .attr('class', 'chart-label exon-label')
              .text(data.viz.exon_label)
              .transition()
              .duration(250)
              .attr('y', y(0) - y.rangeBand() - 2);

          }

          // 
          // Draw strand direction arrow and gene label
          //

          // Strand direction arrow
          svg.append('line')
            .attr('class', 'strand-direction')
            .attr('marker-end', 'url(#strandmarker)')
            .each(function(d, i) {
              // If negative draw right to left
              if (data.gene.strand == '-') {
                var x1 = width;
                var x2 = 0;
                // Otherwise draw left to right
              } else {
                var x1 = 0;
                var x2 = width;
              }

              d3.select(this).attr({
                x1: x1,
                y1: -20,
                x2: x2,
                y2: -20
              });

            })

          // Gene label with white border to cover the strand line
          svg.append('text')
            .attr('y', -15)
            .attr('x', width / 2)
            .attr('text-anchor', 'middle')
            .attr('stroke', '#fff')
            .attr('stroke-width', 10)
            .attr('stroke-linejoin', 'round')
            .attr('class', 'gene-label')
            .text(data.gene.label);

          // Actual gene label, on top of strand direction arrow
          svg.append('text')
            .attr('y', -15)
            .attr('x', width / 2)
            .attr('text-anchor', 'middle')
            .attr('class', 'gene-label')
            .text(data.gene.label);

          // 
          // Line showing current X value on hover
          //

          var xIndicator = svg.append('g')
            .attr('class', 'x-indicator')
            .style('display', 'none');

          xIndicator.append('line')
            .attr('y1', 0)
            .attr('y2', height)
            .attr('x1', 0)
            .attr('x2', 0)

          xIndicator.append('text')
            .attr('transform', 'translate(0,'+height+')')
            .attr('text-anchor', 'middle')
            .attr('stroke', '#fff')
            .attr('stroke-width', 10)
            .attr('stroke-linejoin', 'round')

          xIndicator.append('text')
            .attr('transform', 'translate(0,'+height+')')
            .attr('text-anchor', 'middle');

          svg.on('mouseover', function() { xIndicator.style('display', null); })
            .on('mouseout', function() { xIndicator.style('display', 'none'); })
            .on('mousemove', function(d){
              var xPos = d3.mouse(this)[0];
              xIndicator.attr('transform', 'translate(' + xPos + ', 0)');
              xIndicator.selectAll('text').text(format(Math.floor(x.invert(xPos))));
            });

          // 
          // Mouse events for guide
          //

          svg.selectAll('.guide')
            .on('mouseover', function(d) {

              // Set the active guide to this one
              scope.activeGuide.id = d.id;
              scope.$apply();

              // If recommended show rank
              if (d.pick_order) {
                tooltip.select('.rank').html('<strong class="text-primary">Rank: ' + d.pick_order + '</strong>');
              } else {
                tooltip.select('.rank').html('<strong class="text-warning">Not Recommended</strong>');
              }

              // Write guide data to tooltip element
              tooltip.select('.target').html('<strong>Target: </strong><strong class="mono">' + d.target + '</span>');
              tooltip.select('.location').html('<strong>Cutsite: </strong>' + format(d.cutsite));
              tooltip.select('.exon').html('<strong>Exon: </strong>' + d.exon);
              tooltip.select('.on-target-score').html('<strong>On Target Score: </strong>' + d.azimuth_display);
              tooltip.select('.off-targets').html('<strong>Off Targets: </strong>' + d.offtarget.toString());

              // Show the tooltip
              tooltip.style('opacity', 1)
                .style('top', (parseFloat(d3.select(this).attr("y1")) - 80) - y.rangeBand() / 2 + 'px') // Position based on guide position
                .style('left', d3.event.layerX + 'px'); // Position based on mouse position

            })
            .on('mousemove', function(d) {

              // Move the tooltip when the mouse moves
              tooltip.style('left', d3.event.layerX + 'px');

            })
            .on('mouseout', function() {

              // Unset the active guide
              scope.activeGuide.id = undefined;
              scope.$apply();

              // Hide the tooltip
              tooltip.style('opacity', 0);
            });

        }

      }
    };

  }]);
angular.module('app')
  .directive('geneSelect', function(){
    return {
      scope: {
        form: '=',
        selected: '=',
        resultCount: '=',
        selectType: '@'
      },
      templateUrl: './views/shared/_gene-select.0a3f2460.html',
      controller: ['$scope', '$q', 'Flash', 'design', 'es', 'queryBuilder', function($scope, $q, Flash, design, es, queryBuilder) {

        $scope.resultCount = 0;

        // Load kit gene results
        $scope.refreshKitGenes = function(query, taxID) {

            var query  = {
                'query': {
                  'bool': {
                    'must': {
                      'multi_match': {
                        'query': query,
                        'fields': ['gene_id^2', 'symbol^8', 'description', 'synonyms^2'], // want to lean towards symbol
                        'type': 'phrase_prefix'
                      }
                    },
                    'filter': [
                      {'term': { 'gko': true }}
                    ] 
                  }
                }
              };

             return es.search({
              index: 'gene_indices',
              size: 50,
              method: 'GET',
              source: JSON.stringify(query)

            }).then(function(response) {

              $scope.resultCount = response.hits.hits.length;
              return response.hits.hits;

            });
          }

          // Load default gene results
          $scope.refreshGenes = function(query, taxID) {

          if(taxID != undefined) {

            var query = {
                'query': {
                  'bool': {
                    'must': {
                      'multi_match': {
                        'query': query,
                        'fields': ['gene_id^2', 'symbol^4', 'ensembl_id', 'description', 'synonyms^2'],
                        'type': 'phrase_prefix'
                      }
                    },
                    'filter': [
                      {'term': { 'tax_id': taxID }}
                    ]
                  }
                }
              };

            return es.search({
              index: 'gene_indices',
              size: 50,
              method: 'GET',
              source: JSON.stringify(query)
              
            }).then(function(response) {

              $scope.resultCount = response.hits.hits.length;
              return response.hits.hits;

            });
          }
        }

        // This is to fix a bug where sometimes the form would not validate pasted values
        // Sometimes results in multiple API calls to confirm
        $scope.geneSelected = function($item, $model, $label, $event){
          $scope.form.gene.$validate();
        }

        // We confirm every request before starting the design process.
        $scope.ncbi_help_url = 'https://www.ncbi.nlm.nih.gov/gene/?term=';
        $scope.ensembl_url = 'http://www.ensembl.org/Multi/Search/Results?site=ensembl_all&q=';

        $scope.isGeneBad = function(geneValue){
          var def = $q.defer();

          if(geneValue != undefined){

            // Verify the gene is an object
            if (typeof geneValue === 'string' || geneValue instanceof String) {

              // It's a string, don't hit the iso exists endpoint
              def.resolve(); // Gene isn't bad, just going to be a direct query

            } else {

              // Otherwise we make a quick request to the exists endpoint to verify at least 1 transcript exists for this

              design.confirmTargetExists(queryBuilder.targetToQuery($scope.selected))
                .then(function(response) {
                  if(response && response.data && response.data.status){
                    if(response.data.status == 'success'){

                      def.resolve(); // Gene exists!

                    } else {
                      // Gene does not exist

                      if($scope.selected.genome.value._source.source == 'ncbi'){
                        $scope.failSource = 'NCBI';
                        $scope.failURL = $scope.ncbi_help_url + $scope.selected.gene.value._source.symbol;
                      } else {
                        $scope.failSource = 'ENSEMBL';
                        $scope.failURL = $scope.ensembl_url + $scope.selected.gene.value._source.gene_id;
                      }
                      def.reject();
                    }
                  }
                })
                .catch(function(response){

                  def.resolve();

                })
            }
          } else {

            def.resolve();

          }

          return def.promise;

        }

      }]
    }
  });

angular.module('app')
  .directive('genomeSelect', function(){
    return {
      templateUrl: './views/shared/_genome-select.7d199deb.html',
      controller: ['$scope', 'es', function($scope, es) {
        
        // Load results when user types
        $scope.refreshGenomes = function(query) {
          var query = {
            'query':
              {
                'multi_match': {
                  'query': query,
                  'fields': ['tags', 'description', 'species', 'shortname'],
                  'type': 'phrase_prefix',
                  'slop': 10              
                }   
              }
            };

          return es.search({
            index: 'genome_indices',
            size: 50,
            method: 'GET',
            source: JSON.stringify(query)
             
          }).then(function(response) {

            return response.hits.hits;
            
          });
        }

        $scope.setSelectedGenome = function(shortname) {
          var query = {
            'query':
              {
                'match': {
                  'shortname': shortname
                }   
              }
            };

          es.search({
            index: 'genome_indices',
            size: 1,
            method: 'GET',
            source: JSON.stringify(query)
             
          }).then(function(response) {

            $scope.selected.genome = { value: response.hits.hits[0] };
            
          });
        }

      }],
      scope: {
        form: '=',
        selected: '='
      }
    }
  });
// Miscellaneous helper directives

angular.module('app')
  // The animated logo loader
  .directive('logoLoader', [function () {
    return {
      restrict: 'EA',
      template: '<div class="logo-loader" ><div><div></div><div></div><div></div><div></div></div></div>'
    };
  }])
  // Help tooltip elements
  .directive('helpTooltip', [function () {
    return {
      restrict: 'EA',
      scope: {
        text: '@'
      },
      template: '<span class="help-icon" uib-tooltip="{{text}}" tooltip-append-to-body="true" tooltip-placement="auto top"><i class="material-icons md-16">help_outline</i></span></span>'
    };
  }])
  // Back button
  .directive('back', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.history.back();
          });
      }
    };
  }])
  // Reload button
  .directive('reload', ['$window', function($window) {
    return {
      restrict: 'EA',
      link: function (scope, element, attrs) {
          element.bind('click', function () {
              $window.location.reload();
          });
      }
    };
  }])
  // Waypoints
  .directive('waypoint', [function(){
    return {
      restrict: 'EA',
      link: function(scope, element, attrs) {
        new Waypoint({
          element: element[0],
          handler: function(direction) {

            if(direction == 'down') {
              element.removeClass('scrolled-to scrolled-to-up').addClass('scrolled-to scrolled-to-down');
            } else if(direction == 'up') {
              element.removeClass('scrolled-to-down').addClass('scrolled-to-up');
            }
            
          },
          offset: '50%'
        })
      }
    }
  }])

  .filter('highlight', function() {
    return function(text, phrase) {
      return phrase 
        ? text.replace(new RegExp('('+phrase+')', 'gi'), '<span class="highlight">$1</span>') 
        : text;
    };
  });





 
angular.module('app')
  .directive('hubspotForm', [function () {
      return {
        restrict: 'EA',
        link: function (scope, element, attrs) {
          hbspt.forms.create({
            css: '',
            portalId: '2418554',
            formId: attrs.formId,
            target: '#'+attrs.id,
            formInstanceId: attrs.id,
            submitButtonClass: 'btn btn-primary btn-lg '+attrs.btnClass,
            errorClass: 'has-error',
            errorMessageClass: 'help-block has-error',
            inlineMessage: attrs.inlineMessage,
            onFormSubmit: function() {
              //Hacky, this is to set that the user has filled out the gree gko form when it's submitted
              if(attrs.formId == '849293d2-99c2-4b64-85cf-1cf41dfbc580') {
                scope.$root.hsContact.filled_free_gko_form = true;
                scope.$apply();
              }
            }
          });
        }
      };
    }])
app.directive('shareMenu', [function () {
  return {
    restrict: 'EA',
    scope: {
      dropdownClass: '@'
    },
    templateUrl: './views/shared/_share-menu.5e4bf108.html'
  };
}])
app.directive('validateDiagram', [function () {
  return {
    restrict: 'EA',
    scope: {
      dx: '='
    },
    template: '<ng-include src="getDiagramUrl()"/>',
    link: function(scope) {

      scope.getDiagramUrl = function() {
        if(scope.dx.target != undefined) {
          if (scope.dx.target.pam_right == true) {
            return './views/validate/_validate-diagram-pr-true.d13d0b77.html';
          }

          if (scope.dx.target.pam_right == false) {
            return './views/validate/_validate-diagram-pr-false.b3fe946b.html';
          }
        }
      }

    }
  };
}])
angular.module('app')
  .service('design', ['$http', '$q', 'envConfig', function ($http, $q, envConfig) {

      var designCache;

      this.getResults = function (query) {

        var d = $q.defer();

        // If the designCache doesn't have kit data in it, we can use it
        if( designCache && designCache.data && !designCache.data.design_json) {
          console.log('Loading Design Results From Cache');
          d.resolve(designCache);
        }
        else {
          console.log('API Design Request Made');
          $http({
            method: 'POST',
            url: envConfig.APIURL + '/design',
            data: query,
            headers: {'Accept': 'application/json'}
          }).then(
            function success(response) {
                designCache = response;
                d.resolve(designCache);
            },
            function failure(reason) {
                d.reject(reason);
            }
          );
        }

        return d.promise;
      }

      this.getKitResults = function (query) {

        var d = $q.defer();

        // If the designCache has kit data in it, we can use it
        if (designCache && designCache.data && designCache.data.design_json) {
          console.log('Loading Design Kit Results From Cache');
          d.resolve(designCache);
        }
        else {
          console.log('API Design Kit Request Made');
          $http({
            method: 'POST',
            url: envConfig.APIURL + '/gko',
            data: query,
            headers: {'Accept': 'application/json'}
          }).then(
            function success(response) {
                designCache = response;
                d.resolve(designCache);
            },
            function failure(reason) {
                d.reject(reason);
            }
          );
        }

        return d.promise;
      }



      this.clearCache = function() {
        designCache = null;
      }

      this.getCache = function() {
        return designCache;
      }

      this.confirmTargetExists = function (query) {
          console.log('API Exists Request Made');
          return $http({
              method: 'POST',
              url: envConfig.APIURL + '/exists',
              data: query,
              headers: {'Accept': 'application/json'}
          })
      }

      this.downloadReport = function (dx, guides, format) {

          var download_format = format ? format : 'gb';

          var guide_ids = [];
          for (var i = 0; i < guides.length; i++) {
              guide_ids.push(guides[i].id);
          }

          console.log(dx.gene);

          var query = {
              'genome': dx.genome.shortname,
              'transcript': dx.gene.transcript.key,
              'guides': guide_ids,
              'format': download_format
          }

          return $http({
              method: 'POST',
              url: envConfig.APIURL + '/report',
              data: query,
              headers: {'Accept': 'application/json'}
          })

      }

  }]);
angular.module('app')
  .service('es', ['esFactory', 'envConfig', function (esFactory, envConfig) {
    return esFactory({ host: envConfig.esURL, port:80});
  }]);

angular.module('app')
.service('hubspot', ['$http', '$q', '$cookies', '$filter', 'envConfig', function ($http, $q, $cookies, $filter, envConfig) {

  this.getContactData = function () {
    
    var d = $q.defer();

    var utk = $cookies.get('hubspotutk');
    console.log('hs-utk: ', utk);
    
    if(utk != undefined) {
      $http({
        method: 'GET',
        url: envConfig.hubspot.utkAPIURL + '/' + utk + '?&showListMemberships=False&propertyMode=value_only&property=lifecyclestage',
      }).then(
        function success(response) {
          if(response.data['form-submissions'] != undefined) {
            var filledForm = $filter('filter')(response.data['form-submissions'], {'form-id' : '849293d2-99c2-4b64-85cf-1cf41dfbc580'}).length != 0;
          } else {
            var filledForm = false;
          }
          if(response.data.properties != undefined) {
            var isCustomer = response.data.properties.lifecyclestage.value == 'customer';
          } else {
            var isCustomer = false;
          }
          d.resolve({
            filled_free_gko_form: filledForm,
            is_customer: isCustomer,
          });
        },
        function failure(reason) {
          d.reject(reason);
        }
      );
    } else {
      d.reject('UTK is undefined');
    }
    
    return d.promise;
  };

}]);
angular.module('app').service('queryBuilder', function() {
  // Return the validate query
  //
  this.validateQuery = function(selected) {
    var query = {
      genome: selected.genome.value._source.shortname,
      nuclease: 'cas9',
      guide: selected.guide.value
    };
    return query;
  };

  // Return the design query
  //
  this.targetToQuery = function(selectedTarget) {
    var query = {};

    // Direct look up. Needed for looking up specific transcripts or names that aren't in the genome database
    // If the gene is a string, it didn't match with anything and we do a direct query with the string

    if (
      typeof selectedTarget.gene.value === 'string' ||
      selectedTarget.gene.value instanceof String
    ) {
      query = {
        direct: selectedTarget.gene.value
      };

      // otherwise we have details about the gene from the reference lookup
    } else if (typeof selectedTarget.gene.value._source != undefined) {
      // if it's an NCBI genome, we likely have a working Gene id
      if (selectedTarget.genome.value._source.source == 'ncbi') {
        query = {
          gene_id: selectedTarget.gene.value._source.gene_id,
          symbol: selectedTarget.gene.value._source.symbol
        };

        // otherwise it's from another source, try to use the ensembl id first if present
        // if not, just use the gene id
        // always include symbol as a backup (api can handle it)
      } else {
        if (selectedTarget.gene.value._source.ensembl_id != undefined) {
          query = {
            gene_id: selectedTarget.gene.value._source.ensembl_id,
            symbol: selectedTarget.gene.value._source.symbol
          };
        } else {
          query = {
            gene_id: selectedTarget.gene.value._source.gene_id,
            symbol: selectedTarget.gene.value._source.symbol
          };
        }
      }
    }

    query.genome = selectedTarget.genome.value._source.shortname;
    query.nuclease = 'cas9';

    return query;
  };

  // Return the kit query, which is a simplied version of the design query
  //
  this.kitToQuery = function(selectedTarget) {
    if (selectedTarget.gene != undefined) {
      var query = {};

      query.symbol = selectedTarget.gene.value._source.symbol;
      query.genome = selectedTarget.genome.value._source.shortname;
      query.nuclease = 'cas9';

      return query;
    } else {
      return;
    }
  };
});

angular.module('app').service('store', [
  '$http',
  '$timeout',
  'envConfig',
  function($http, $timeout, envConfig) {
    this.getStoreInfo = function(query) {
      var headers = {
        Accept: 'application/json',
        SYNTHEGOAPIKEY: envConfig.store.APIKey
      };

      var r = {
        method: 'POST',
        url: envConfig.store.APIURL + '/order/',
        data: query,
        headers: headers
      };
      return $http(r);
    };

    this.getProducts = function() {
      return [
        {
          id: '1',
          name: 'ez_sgRNA_oligonucleotide_modified',
          label: 'Synthetic Modified sgRNA',
          html_label:
            'Synthetic Modified <span class="force-lower">sg</span>RNA'
        },
        {
          id: '2',
          name: 'ez_sgRNA_oligonucleotide',
          label: 'Synthetic sgRNA',
          html_label: 'Synthetic <span class="force-lower">sg</span>RNA'
        }
      ];
    };

    this.getYieldOptions = function() {
      return [
        {
          id: '1.5nmol',
          display: '1.5 nmol'
        },
        {
          id: '3nmol',
          display: '3 nmol'
        }
      ];
    };

    this.getModProduct = function() {
      return this.getProducts()[0];
    };

    this._generateRequestItems = function(guides, gene, genome, product, guaranteed_yield) {
      var items = [];
      for (var i = 0; i < guides.length; i++) {
        var guide = guides[i];

        var pam_right = guide.pam_right;
        var label;
        if (pam_right) {
          label = gene.label + '+' + guide.cutsite;
        } else {
          label = gene.label + '-' + guide.cutsite;
        }

        items.push({
          label: label,
          sequence: guide.guide,
          exon: guide.exon,
          product_type: product.name,
          pam_right: pam_right,
          guaranteed_yield: guaranteed_yield,
          cutsite: guide.cutsite,
          genome: {
            label: genome.label,
            shortname: genome.shortname,
            taxonomy_id: genome.taxonomy_id
          },
          gene: {
            gene_id: gene.gene_id,
            label: gene.label,
            chromosome: gene.chromosome,
            exon: gene.exon,
            symbol: gene.symbol
          }
        });
      }
      return items;
    };

    this.generateStoreRequest = function(
      guides,
      genome,
      gene,
      product,
      guaranteed_yield,
      kit_name
    ) {
      var query = {};

      items = this._generateRequestItems(guides, gene, genome, product, guaranteed_yield);

      if (kit_name != undefined) {
        var kit = {};
        kit.sequence = items;
        kit.product_type = 'gko_kit_homo_sapiens';
        kit.label = kit_name;
        query['items'] = [kit];
      } else {
        query['items'] = items;
      }

      return query;
    };
  }
]);

angular.module('app').service('userSelections', function() {
  return {
    target: {
      gene: null,
      genome: null,
      guide: null
    },
    guideCollection: {},
    selectedGuides: []
  };
});

angular.module('app')
  .service('util', function () {

    this.b64toBlob = function(b64Data, contentType, sliceSize) {

      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;

    }

    this.saveBlobAs = function (blob, fileName) {
      if (typeof navigator.msSaveBlob == "function") return navigator.msSaveBlob(blob, fileName);
  
      var saver = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
      var blobURL = (saver.href = URL.createObjectURL(blob)),
        body = document.body;
  
      saver.download = fileName;
  
      body.appendChild(saver);
      saver.dispatchEvent(new MouseEvent("click"));
      body.removeChild(saver);
      URL.revokeObjectURL(blobURL);
    };


    this.hasRequiredParams = function($stateParams, requiredParams) {
      return requiredParams.every( function(param) {
        return $stateParams[param] != undefined
      });
    }

  });

angular.module('app')
  .service('validate', ['$http', '$q', 'envConfig', function ($http, $q, envConfig) {

      var validateCache;

      this.getResults = function (query) {

        var d = $q.defer();

        if( validateCache ) {
          console.log('Loading Validate Results From Cache');
          d.resolve(validateCache);
        }
        else {
          console.log('API Validate Request Made');
          $http({
            method: 'POST',
            url: envConfig.APIURL + '/validate',
            data: query,
            headers: {'Accept': 'application/json'}
          }).then(
            function success(response) {
                validateCache = response;
                d.resolve(response);
            },
            function failure(reason) {
                d.reject(reason);
            }
          );
        }

        return d.promise;
      }

      this.clearCache = function() {
        validateCache = null;
      }

      this.getCache = function() {
        return validateCache;
      }

      this.confirmGuide = function (query) {

        // Caching this was causing problems, so we only cache results

        var d = $q.defer();

        console.log('API Confirm Request Made');
        $http({
          method: 'POST',
          url: envConfig.APIURL + '/confirm',
          data: query,
          headers: {'Accept': 'application/json'}
        }).then(
          function success(response) {
              d.resolve(response);
          },
          function failure(reason) {
              d.reject(reason);
          }
        );

        return d.promise;
      }

  }]);
angular.module('app')
  .controller('designForm', ['$scope', '$state', '$stateParams', 'Flash', 'design', 'userSelections', 'queryBuilder', function ($scope, $state, $stateParams, Flash, design, userSelections, queryBuilder) {

    $scope.selectedTarget = userSelections.target;

    // Clear selected guides
    userSelections.guideCollection = {};
    userSelections.selectedGuides = [];


    $scope.$watch('selectedTarget.genome.value', function(newGenomeValue, oldGenomeValue){
      if(newGenomeValue != oldGenomeValue) {

        // Clear out cached results
        design.clearCache();

        // Clear selected gene
        $scope.selectedTarget.gene = undefined;

      }
    });

    $scope.$watch('selectedTarget.gene.value', function(newGeneValue, oldGeneValue){      
      
      if(typeof newGeneValue === 'string' || newGeneValue instanceof String) {
        $scope.targetIsObject = false;
      } else {
        $scope.targetIsObject = true;
      }

      if(newGeneValue != oldGeneValue) {

        // Clear out cached results
        design.clearCache();

      }

    });

    // On submit, if form is valid, go to results
    $scope.formSubmit = function() {

      if($scope.designForm.$valid) {
        $state.go('design.results.recommended', queryBuilder.targetToQuery($scope.selectedTarget));
      }

    }

  }]);

angular.module('app')
  .controller('designResults', ['$scope', '$state', '$stateParams', '$transitions', '$filter', 'Flash', 'design', 'userSelections', 'util', function ($scope, $state, $stateParams, $transitions, $filter, Flash, design, userSelections, util) {

    // Default settings
    $scope.recommendedGuideLimit = 4;
    $scope.guideLimit = 40;

    $scope.guideCollection = userSelections.guideCollection;
    $scope.selectedGuides = userSelections.selectedGuides;

    // The user is doing a direct only query
    $scope.isOnlyDirect = $stateParams.direct != undefined && $stateParams.symbol == undefined;

    // This has to be an object instead of primitive for directives to work
    $scope.status = {
      loading: false,
      updating: false,
    }

    $scope.activeGuide = { id: undefined };

    var responseSuccess = function(response) {

      Flash.clear();

      if(response.data.status == 'failed') {
        console.log('FAILED', response);
        if($scope.status.loading) {
          $scope.status.loading = false;
          $state.go('error', {message: response.data.message, backTo: 'design'});
        } else {
          $scope.status.updating = false;
          Flash.create('danger', '<strong>Something went wrong: </strong>' + response.data.message);
        }
        return;
      }

      $scope.dx = response.data;
      $scope.status.updating = false;

      $scope.selectedTranscript = { value: $scope.dx.gene.transcript };
      $scope.selectedExon = { value: $scope.dx.gene.exon };

    }

    var responseError = function(error) {

      console.log('ERROR', error);
      Flash.clear();
      $state.go('error', {message: "Problem connecting to API.", backTo: 'design'});

    }

    // On page load design guides from URL params
    if($stateParams.nuclease != undefined && $stateParams.genome != undefined){

      // Send direct only queries to advanced
      if($scope.isOnlyDirect) {
        $state.go('design.results.advanced');
      }

      $scope.status.loading = true;

      design.getResults($stateParams)
        .then(function(response) {
          responseSuccess(response);
        })
        .catch(function(error) {
          responseError(error);
        });

    } else {
      $state.go('design');
    }

    $transitions.onSuccess({ to: 'design.results.advanced', from: 'design.results.advanced' }, function(transition){

      // The user changed exon or transcript
      $scope.advancedDirty = true;

      // On transition from self (exon or transcript selection), show simpler loader
      $scope.status.updating = true;

      // We can just send the params from the transition right in
      design.getResults(transition.params())
        .then(function(response) {
          responseSuccess(response);

          //Reset selected guides
          $scope.guideCollection = userSelections.guideCollection = {};
          setPickedGuides($scope.dx.guides);

        })
        .catch(function(error) {
          responseError(error);
        });

    });

    // Requery if user exits advanced view
    $transitions.onSuccess({ to: 'design.results.recommended', from: 'design.results.advanced' }, function(transition){

      if(transition.params().direct != undefined && transition.params().symbol == undefined) {
        $state.go('design.results.recommended', {exon: ''});
      } else {
        $state.go('design.results.recommended', {exon: '', transcript: ''});
      }

      //Clear selected guides and set back to recommended
      $scope.guideCollection = userSelections.guideCollection = {};
      setPickedGuides($scope.dx.guides);

      // Only run a new query if the user changed the exon or transcript
      if($scope.advancedDirty) {

        // Clear results cache
        design.clearCache();

        $scope.selectedExon = undefined;
        $scope.selectedTranscript = undefined;
        $scope.advancedDirty = false;

        $scope.status.updating = true;

        design.getResults(transition.params())
          .then(function(response) {
            responseSuccess(response);
          })
          .catch(function(error) {
            responseError(error);
          });

      }
    });

    $scope.recommendedGuideCount = function(){

      return Math.min($scope.dx.num_picked, $scope.recommendedGuideLimit);

    }

    $scope.onExonChange = function(exon){
      design.clearCache();
      $scope.selectedExon = exon;
      $state.go('.', {exon: $scope.selectedExon.value.value});

    }

    $scope.onTranscriptChange = function(transcript){
      design.clearCache();
      $scope.selectedTranscript = transcript;
      $state.go('.', {direct: $scope.selectedTranscript.value.key, exon: ''});

    }

    $scope.downloadReport = function(){

      design.downloadReport($scope.dx, $scope.selectedGuides)
        .then(function(response) {

          var filename = $scope.dx.gene.symbol + '-SYNTHEGO-DESIGN.gb';
          var data = util.b64toBlob(response.data.b64);
          util.saveBlobAs(data, filename);

        })
        .catch(function(response){
          Flash.create('danger', '<strong>Unable to download sequence information at this time.</strong>');
          console.log('ERROR', response)
        })
    }

    // Collects the selected guides
    $scope.$watchCollection('guideCollection', function () {
      $scope.selectedGuides.length = 0; //Clear the array
      angular.forEach($scope.guideCollection, function (guide, key) {
        if (guide) {
          $scope.selectedGuides.push(guide);
        }
      });

    });

    // Set picked guides to be selected by default
    var setPickedGuides = function(guides) {
      angular.forEach(guides, function (guide, key) {
        if (guide.pick_order <= $scope.recommendedGuideLimit) {
          $scope.guideCollection[guide.id] = guide;
        }
      });
    };

    // If the guides change, set the picked ones
    $scope.$watchCollection('dx.guides', function (guides) {
      // Only set recommended guides if there aren't any selected yet
      if(angular.equals($scope.guideCollection, {})) {
        setPickedGuides(guides);
      }

    });

  }]);

angular.module('app')
  .controller('error', ['$scope', '$stateParams', function ($scope, $stateParams) {

    $scope.errorMessage = $stateParams.message;
    $scope.backTo = $stateParams.backTo;
    
  }]);

angular.module('app')
  .controller('kitForm', ['$scope', '$state', '$stateParams', '$uibModal', '$localStorage', 'envConfig', 'Flash', 'design', 'userSelections', 'es', 'queryBuilder', 'hubspot', function ($scope, $state, $stateParams, $uibModal, $localStorage, envConfig, Flash, design, userSelections, es, queryBuilder, hubspot) {

    var kitGenome = {
      value: {
        "_index": "genome_indices",
        "_type": "genome",
        "_id": envConfig.gko.id,
        "_score": 1,
        "_source": {
          "crispr_sites": 304792603,
          "description": "Gencode Release 26 (GRCh38.p10) Primary",
          "tags": [
            "Human",
            "Homo sapiens",
            "gencode"
          ],
          "annotation_url": "https://www.gencodegenes.org/releases/26.html",
          "species": "Homo sapiens",
          "rds_resource": 0,
          "source": "gencode",
          "offtarget_count": 153,
          "shortname": envConfig.gko.id,
          "pipeline_version": "0.6.0",
          "assembly_url": "https://www.gencodegenes.org/releases/26.html",
          "tax_id": 9606
        }
      }
    }

    $scope.selectedTarget = userSelections.target;

    // Set genome
    $scope.selectedTarget.genome = kitGenome

    // Reset gene just to be safe
    $scope.selectedTarget.gene = null

    // Clear selected guides
    userSelections.guideCollection = {};
    userSelections.selectedGuides = [];

    $scope.$watch('selectedTarget.gene.value', function(newGeneValue, oldGeneValue){

      if(newGeneValue != oldGeneValue) {

        // Clear out cached results
        design.clearCache();

      }

    });

    // On submit, if form is valid, go to results
    $scope.formSubmit = function() {

      if($scope.kitForm.$valid) {
        $state.go('kit.results', queryBuilder.kitToQuery($scope.selectedTarget));
      }

    }

  }]);

angular.module('app')
  .controller('kitResults', ['$scope', '$state', '$stateParams', 'design', 'userSelections', function ($scope, $state, $stateParams, design, userSelections) {

    // Default settings
    $scope.recommendedGuideLimit = 4;

    $scope.selectedGuides = userSelections.selectedGuides;

    // This has to be an object instead of primitive for directives to work
    $scope.status = {
      loading: false
    }

    var responseSuccess = function(response) {

      if(response.data.status == 'failed') {
        $scope.status.loading = false;
        $state.go('error', {message: response.data.message, backTo: 'kit'});
        return;
      }

      // The GKO response only work for designs that have 4+ guides.
      // PREVIOUS: $scope.dx = response.data;
      // The response contains 2 things:
      //  the full design json (same format as design but independently calculated and no guarantee of same guides)
      //  the gko kit part number
      $scope.dx = response.data.design_json;
      $scope.dx.partnumber = response.data.partnumber;

      // If there are guides
      if($scope.dx.guides != undefined) {
        // Set the selected guides
        angular.forEach($scope.dx.guides.slice(0, $scope.recommendedGuideLimit), function (guide, key) {
          $scope.selectedGuides.push(guide);
        });
      }
    }

    var responseError = function(error) {

      console.log('ERROR', error);
      $state.go('error', {message: "Problem connecting to API.", backTo: 'kit'});

    }

    // On page load design guides from URL params
    if($stateParams.nuclease != undefined && $stateParams.genome != undefined){

      $scope.status.loading = true;
      $scope.dx = null;

      $scope.selectedGuides.length = 0; // Clear selected guides

      design.getKitResults($stateParams)
        .then(function(response) {

          responseSuccess(response);

        })
        .catch(function(error) {

          responseError(error);

        });
    } else {
      $state.go('kit');
    }

    // Watch for the loader to finish, then go to summary
    $scope.$watch('status.loading', function (loading) {
      if(loading == false) {
        if($scope.selectedGuides != []) {
          // Go to the summary replacing this URL so it doesn't show up in history
          $state.go('kit.results.summary', {}, { location: 'replace' });
        } else {
          // Error, there are no picked guides
          $state.go('error', {message: "No recommended guides found.", backTo: 'kit'});
        }
      }
    });

  }]);

angular.module('app')
  .controller('modals', ['$scope', '$uibModal', function ($scope, $uibModal) {
    $scope.openModal = function (templateUrl, size) {

      $uibModal.open({
        templateUrl: templateUrl,
        size: size
      }).result.then(function(){}, function(res){});
      
    }
  }]);

angular.module('app')
  .controller('resultsSummary', ['$scope', '$state', '$stateParams', '$transitions', 'envConfig', 'Flash', 'store', 'userSelections', 'design', function ($scope, $state, $stateParams, $transitions, envConfig, Flash, store, userSelections, design) {

    $scope.selectedGuides = userSelections.selectedGuides;
    $scope.productOptions = store.getProducts();
    $scope.selectedProduct = { value: $scope.productOptions[0] };
    $scope.yieldOptions = store.getYieldOptions();
    $scope.selectedYield = { value: $scope.yieldOptions[0] };
    $scope.isCollapsed = true;

    // Conditionals to display/hide certain things in template
    // This way if there is no data (load from URL) the conditionals will be correct
    $scope.isKit = $state.$current.parent.parent.name == 'kit';
    $scope.isDesign = $state.$current.parent.parent.name == 'design';

    if(design.getCache() && design.getCache().data) {
      if(design.getCache().data.partnumber != undefined) {
        //It's a kit
        $scope.dx = design.getCache().data.design_json;
        $scope.partnumber = design.getCache().data.partnumber;
      } else {
        //Not a kit
        $scope.dx = design.getCache().data;
      }
    }

    if($scope.selectedGuides.length == 0 || !$scope.dx) {
      // Go back if no guides
      if($scope.isKit) {
        $state.go('kit.results', $stateParams);
      } else if($scope.isDesign) {
        $state.go('design.results.recommended', $stateParams);
      } else {
        $state.go('design');
      }

    } else {

      $scope.$watchGroup(['selectedProduct.value', 'selectedYield.value'], function(selectedValues) {
        console.log($scope.dx.partnumber, 'store request, product', $scope)
        var productType = selectedValues[0]
        var productYield = selectedValues[1]
        store.getStoreInfo(store.generateStoreRequest($scope.selectedGuides, $scope.dx.genome, $scope.dx.gene, productType, productYield, $scope.dx.partnumber ))
          .then(function(response){
            console.log(response);
            $scope.storeInfo = response.data;

          })
          .catch(function(error){
            console.log('ERROR', error);
            $state.go('error', {message: 'Problem retrieving products and prices from the store.', backTo: $scope.isDesign ? 'design' : 'kit'});
          })

      });

    }

    $scope.isGenomeHuman = $scope.dx.genome.shortname.startsWith('homo_sapiens_gencode_26');

    // GKO table name does not match the source genome shortname, therefore, the code can't use state params for linking
    // out to validate if it is a kit
    if ($scope.isKit) {
      $scope.genomeSourceShortname = envConfig.gko.genomeSourceShortname;
    } else {
      $scope.genomeSourceShortname = $stateParams.genome;
    }

    //In case of transitioning from long pages make sure we scroll to top when arriving here
    $transitions.onSuccess({ to: 'design.results.summary', from: '**' }, function(transition){
      window.scrollTo(0, 0);
    });

  }]);

angular.module('app')
  .controller('validateForm', ['$scope', '$state', '$q', 'validate', 'userSelections', 'queryBuilder', function ($scope, $state, $q, validate, userSelections, queryBuilder) {

    $scope.selectedTarget = userSelections.target;

    $scope.isGuideBad = function(){

      // Use a promise for UI Validate async
      var def = $q.defer();

      $scope.badGuideMessage = undefined;

      // Confirm the guide if the genome is valid and the guide is the correct length and pattern
      // Or if the guide has already been determined to be bad, it might not be for a new genome
      if($scope.selectedTarget.genome != undefined && ($scope.validateForm.guide.$valid || $scope.validateForm.guide.$error.badGuide)) {

        validate.confirmGuide(queryBuilder.validateQuery($scope.selectedTarget))
          .then(function(response) {
            if(response.data.status == 'failed') {
              $scope.badGuideMessage = response.data.message;
              def.reject();
            } else {
              $scope.badGuideMessage = undefined;
              def.resolve();
            }
          })
          .catch(function(response){
            console.log('ERROR', response)
          })

      } else {
        
        // Otherwise assume it's valid
        def.resolve();

      }

      return def.promise;

    }

    $scope.$watchGroup(['selectedTarget.genome.value', 'selectedTarget.guide.value'], function(newTarget, oldTarget){
      if(!angular.equals(newTarget,oldTarget)) {
        validate.clearCache();
      }
    });

    // On submit, if form is valid, go to results
    $scope.formSubmit = function() {
      if($scope.validateForm.$valid) {
        $state.go('validate.results', queryBuilder.validateQuery($scope.selectedTarget));
      }
    }

  }]);

angular.module('app')
  .controller('validateResults', ['$scope', '$state', '$stateParams', '$transitions', 'Flash', 'validate', function ($scope, $state, $stateParams, $transitions, Flash, validate) {

    $scope.showFilters = false;

    $scope.geneFilters = [
      {label: 'All', value: undefined},
      {label: 'Protein Coding Genes', value: ''},
      {label: 'Non-Coding Regions', value: '!'}
    ];

    $scope.search = {
      chromosome_label: undefined,
      gene_name:  $scope.geneFilters[0]
    };

    var responseSuccess = function(response) {

      Flash.clear();
      
      if(response.data.status == 'failed') {
        console.log('FAILED', response);
        $state.go('error', {message: response.data.message, backTo: 'validate'});
      }
      $scope.loading = false;
      $scope.dx = response.data;

    }

    var responseError = function(error) {

      console.log('ERROR', error);
      Flash.clear();
      $state.go('error', {message: "Problem connecting to API.", backTo: 'validate'});
      $scope.loading = false;

    }

    $scope.toggleFilters = function() {
      $scope.showFilters = !$scope.showFilters; 
      $scope.search.chromosome_label = undefined; 
      $scope.search.gene_name = $scope.geneFilters[0];
    }

    Flash.clear();

    // On load if there are url parameters validate the guide
    if($stateParams.genome && $stateParams.nuclease && $stateParams.guide) {

      $scope.loading = true;

      // Load initial validation
      validate.confirmGuide($stateParams)

        .then(function(response) {

          responseSuccess(response);

          // Load off-targets
          validate.getResults($stateParams)
            .then(function(response) {
              responseSuccess(response);
            })
            .catch(function(error) {
              responseError(error);
            });

        })
        .catch(function(error) {
          
          responseError(error);

        });

    }

  }]);
